import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import HowItsWork from "../../assets/images/how-its-work.png"
import { SECTION_IDS } from "../../config/sections"
import { COMPANY_NAME } from "../../config/common"
import { inPixels, isMobileViewport } from "../../utils/common"
import { SectionIdOffset } from "../SectionIdOffset"

import "./HowItWorks.scss"

const CountBubble = ({ number }) => (
  <div className="justify-content-sm-center count-box d-flex align-self-center justify-content-center">
    {number}
  </div>
)

const HeadingAndSubheading = ({ item }) => (
  <>
    <h3 className="tac-sm mt-sm-3">{item.heading}</h3>
    <p className="tac-sm">{item.subheading}</p>
  </>
)

const HowItWorksStep = ({ item, index, isLast, icon }) => {
  const PREFIX_ZERO = false
  const SIMPLE_LAYOUT = true
  const isFirst = index === 0
  const renderBubble = () => (icon || <CountBubble number={index + 1} />)
  if (SIMPLE_LAYOUT) {
    const NUMBER_COL_SIZE = 2
    return (
      <div className={cx("single-item pl-sm-0", { "pb-lg-5": !isLast })}>
        {!isFirst && (
          <div className="howitworks-arrow pb-5 pb-sm-4">
            <span className="animated bounce">&#8595;</span>
          </div>
        )}
        <div className="row">
          <div className={`col-lg-${NUMBER_COL_SIZE} d-flex justify-content-sm-center`}>
            {renderBubble()}
          </div>
          <div className={`col-lg-${12 - NUMBER_COL_SIZE}`}>
            <HeadingAndSubheading item={item} />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="single-item">
      {renderBubble()}
      <HeadingAndSubheading item={item} />
    </div>
  )
}

export const HowItWorks = ({
                             showMainImage = false,
                             variant
                           }) => {

  const renderSteps = () => {
    const itemsCount = variant.content.items.length
    return variant.content.items.map((item, index) => {
      return (
        <HowItWorksStep
          key={item.index}
          icon={variant.iconLibrary ? item.icon : null}
          item={item}
          index={index}
          isLast={index === itemsCount - 1}
        />
      );
    })
  }

  return (
    <div className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className={cx("col-md-12", showMainImage ? "col-lg-6" : "col-lg-12")}>
            <div className="how-its-work-content">
              {variant.subtitle && (<span className="sub-title">{variant.subtitle}</span>)}
              <h2 className="text-center">
                {variant.heading || `How ${COMPANY_NAME} works`}
                <SectionIdOffset id={SECTION_IDS.HOW_IT_WORKS} offsetPx={{ mobile: -100, desktop: -200 }} />
              </h2>
              <p className="text-center">{variant.subheading}</p>
              <div className="inner-box-container pt-lg-3">
                <div className="inner-box">
                  {renderSteps()}
                </div>
              </div>
            </div>
          </div>

          {showMainImage && (
            <div className="col-lg-6 col-md-12">
              <div className="how-its-work-image">
                <img src={HowItsWork} alt="How It's Work" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
