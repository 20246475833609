import React from "react"
import PropTypes from "prop-types"

const noop = () => {
}

export const FeatureCard = ({
                              heading,
                              onHeadingClick = noop,
                              icon = null,
                              subheading,
                              callToAction = null,
                              onHover = noop,
                              onCtaClick = noop,
                              displayCircles = true,
                              ctaText = null,
                              styles = {},
  noCta = false,
                            }) => {

  const renderCta = () => {
    if (noCta) {
      return null;
    }
    if (!callToAction && !noCta) {
      return (
        <>
          <div onClick={onCtaClick}>
            <i className="left-icon flaticon-next-button" />
            {ctaText} <i className="right-icon flaticon-next-button" />
          </div>
        </>
      )
    }
    return callToAction
  }

  return (
    <div className="col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch">
      <div className="single-featured-services-box advanced-card-hover-effect" style={styles}>
        {icon && (
          <div className="icon">
            <i className={icon} />
            {displayCircles && (
              <div className="circles-box">
                <div className="circle-one"></div>
                <div className="circle-two"></div>
              </div>
            )
            }
          </div>
        )}
        <h3 onClick={onHeadingClick}>{heading}</h3>
        <p>{subheading}</p>
        {renderCta()}
      </div>
    </div>
  )
}

FeatureCard.propTypes = {
  heading: PropTypes.string.isRequired,
  onHeadingClick: PropTypes.func,
  icon: PropTypes.string,
  subheading: PropTypes.string.isRequired,
  callToAction: PropTypes.node,
  onCtaClick: PropTypes.func,
  ctaText: PropTypes.string,
  onHover: PropTypes.func,
  styles: PropTypes.object,
  displayCircles: PropTypes.bool,
  noCta: PropTypes.bool,
}
