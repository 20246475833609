import React from "react"
import cx from 'classnames';
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Button } from "../Button/Button"
import BannerImg02 from "../../assets/images/landing_illustration_001.webp"
// import BannerImg from "../../assets/images/banner-img4.png"
import BannerImg from "../../assets/images/reekon_features_autonomous_ticket_registration.svg"
import { RequestDemoButton } from "../Button/RequestDemoButton"
import { CommonPropTypes } from "../propTypes"
import { renderMultilineString } from "../../utils/render"
import { scrollToElement } from "../../utils/common"
import { AnimatedText } from "../AnimatedText/AnimatedText"

const Image = ({
                 src,
                 size = null,
                 height,
                 width,
                 zIndex = 10,
                 alt,
                 className = null
               }) => {
  return (
    <img className={className} src={src} alt={alt} style={{ height: size || height, width: size || width, zIndex }} />
  )
}

const MainBanner = ({ variant }) => {

  const onRequestDemoClick = () => {
    // open calendly
  }

  const handlePrimaryBtnClick = () => {
    scrollToElement(variant.text.scrollToOnClick)
    // scrollTo(variant.text.scrollToOnClick, 'start')
  }

  // React.useEffect(
  //   () => {
  //     scrollTo(variant.text.scrollToOnClick, 'start')
  //   },
  //   []
  // )

  const bannerImgClassName = cx(
    'col-lg-6 col-md-12',
    {
      'hide-sm': variant.layout.hideBannerImageOnMobile,
    },
  );

  return (
    <div className="digital-agency-banner theme-01 pb-sm-5 pb-5 pt-md-0 pt-sm-0 pt-lg-5">
      <div className="container">
        <div className="row align-items-center">
          <div className={bannerImgClassName}>
            <div className="banner-image mt-sm-0">
              {/*<Image src={BannerImg} size={150} alt={"Hello world"} zIndex={20} />*/}
              <img src={BannerImg02} alt="Exemplary customer support" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="banner-content">
              {/*<span className="sub-title">Digital Marketing</span>*/}
              <h1 className="heading">
                {variant.text.heading}
                {/*<AnimatedText tag={'h1'} options={['Gdddd', 'Clean', 'Elegant', 'Mag', 'Ador']}/>*/}
              </h1>
              <p className="subheading mb-sm-5" dangerouslySetInnerHTML={{
                __html: variant.text.subheading
              }} />
              <Button
                onClick={handlePrimaryBtnClick}
                types={["primary"]}
                isLink={false}
                link="/about-us-1"
                label={variant.text.primaryButtonLabel}
                withBtnBoxWrapper={!variant.layout.ctaButtonsSingleRow}
              />
              <RequestDemoButton
                inline={variant.layout.ctaButtonsSingleRow}
                switchTextAndIcon={variant.layout.requestDemoButton.switchTextAndIcon}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MainBanner.propTypes = {
  variant: CommonPropTypes.bannerVariant
}

export default MainBanner
