import React from "react"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import { NavbarStyleThree } from "../components/App/NavbarStyleThree"
import MainBanner from "../components/HomeDemoThree/MainBanner"
import Partner from "../components/Common/Partner"
import CustomerIndustriesSection from "../components/HomeDemoThree/CustomerIndustriesSection"
import { UseCasesSection } from "../components/HomeDemoThree/UseCasesSection"
import AboutSection from "../components/HomeDemoThree/AboutSection"
import { BenefitsSection } from "../components/Common/BenefitsSection"
import SEOAnalysisForm from "../components/Common/SEOAnalysisForm"
import TestimonialStyleTwo from "../components/Common/TestimonialStyleTwo"
import CaseStudiesTwo from "../components/Common/CaseStudiesTwo"
import { HowItWorks } from "../components/Common/HowItWorks"
import OurTeamStyleTwo from "../components/Common/OurTeamStyleTwo"
import LatestBlogPost from "../components/Common/LatestBlogPost"
import SubscribeForm from "../components/Common/SubscribeForm"
import Footer from "../components/App/Footer"
import { IntegrationsSection } from "../components/Common/IntegrationsSection"
import { GetStartedWithUs } from "../components/Common/GetStartedWithUs"

import { SEO_TITLES } from "../config/seo"
import {
  GLOBAL_CONFIG, BANNER_VARIANTS, USE_CASES_VARIANTS,
  HOW_IT_WORKS_SECTION_VARIANTS, BENEFITS_SECTION_VARIANTS,
  FOOTER_VARIANTS
} from "../config"

import { CommonHeaderAndFooter } from "../layout/CommonHeaderAndFooter"


const HomeThree = () => {
  const fuck = false
  if (fuck) {
    return (
      <>
        <NavbarStyleThree />
        {/*<HowItWorks variant={HOW_IT_WORKS_SECTION_VARIANTS.ONE} />*/}
        <MainBanner variant={BANNER_VARIANTS.ONE} />
        {/*<Partner />*/}
        {/*<CustomerIndustriesSection showCtaOnCards={false} />*/}
        <UseCasesSection variant={USE_CASES_VARIANTS.ONE} />
        <HowItWorks variant={HOW_IT_WORKS_SECTION_VARIANTS.ONE} />
        {/*<AboutSection />*/}
        <BenefitsSection variant={BENEFITS_SECTION_VARIANTS.ONE} />
        {/*<SEOAnalysisForm />*/}
        {/*<TestimonialStyleTwo />*/}
        {/*<CaseStudiesTwo />*/}
        {/*<OurTeamStyleTwo />*/}
        {/*<LatestBlogPost />*/}
        <IntegrationsSection />
        <GetStartedWithUs mentionCompanyName={false} />
        {/*<SubscribeForm />*/}
      </>
    )
  }
  return (
    <CommonHeaderAndFooter seoTitle={SEO_TITLES.VARIANT3.HOMEPAGE}>
      {/*<HowItWorks variant={HOW_IT_WORKS_SECTION_VARIANTS.ONE} />*/}
      <MainBanner variant={BANNER_VARIANTS.ONE} />
      {/*<Partner />*/}
      {/*<CustomerIndustriesSection showCtaOnCards={false} />*/}
      <UseCasesSection variant={USE_CASES_VARIANTS.ONE} />
      <HowItWorks variant={HOW_IT_WORKS_SECTION_VARIANTS.ONE} />
      {/*<AboutSection />*/}
      <BenefitsSection variant={BENEFITS_SECTION_VARIANTS.ONE} />
      {/*<SEOAnalysisForm />*/}
      {/*<TestimonialStyleTwo />*/}
      {/*<CaseStudiesTwo />*/}
      {/*<OurTeamStyleTwo />*/}
      {/*<LatestBlogPost />*/}
      <IntegrationsSection />
      <GetStartedWithUs mentionCompanyName={false} />
      {/*<SubscribeForm />*/}
    </CommonHeaderAndFooter>
  )
}

export default HomeThree
