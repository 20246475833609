import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import _ from "lodash"

import { WithTooltip } from "../WithTooltip"
import { INTEGRATION_TYPES } from "../../config"
import { SectionIdOffset } from "../SectionIdOffset"
import { SECTION_IDS } from "../../config/sections"

import "./IntegrationsSection.scss"

const INTEGRATIONS_LIST = [
  INTEGRATION_TYPES.INTERCOM,
  INTEGRATION_TYPES.ZENDESK,
  INTEGRATION_TYPES.SERVICENOW,
  INTEGRATION_TYPES.SHOPIFY,
  // INTEGRATION_TYPES.MAGENTO,
  // INTEGRATION_TYPES.BIG_COMMERCE,
  INTEGRATION_TYPES.OKTA,
  // INTEGRATION_TYPES.JIRA,
  // INTEGRATION_TYPES.IVANTI,
  INTEGRATION_TYPES.SLACK,
  INTEGRATION_TYPES.SALESFORCE
  //INTEGRATION_TYPES.FRESHDESK
]

export const IntegrationCard = ({
                                  integration,
                                  logoUpAndTextDown = true,
                                  logoOnly = false,
                                  tooltipOnCards = false
                                }) => {
  const renderLogoAndText = () => {
    if (logoOnly) {
      const Wrapper = (props) => (
        tooltipOnCards ? <>{props.children}</> : (
          <>{props.children}</>
          // <WithTooltip
          //   tooltipId={"integration-" + integration?.name?.toLowerCase()}
          //   tooltipContent={integration.name}
          // >{props.children}</WithTooltip>
        )
      )
      return (
        <Wrapper>
          <img src={integration.logo} alt={integration.name} />
        </Wrapper>
      )
    }
    if (logoUpAndTextDown) {
      return (
        <>
          <img src={integration.logo} alt={integration.name} />
          <h3 className="tac mt-3">{integration.name}</h3>
        </>
      )
    }
    return (
      <>
        {!logoOnly && <h3 className="tac mb-3">{integration.name}</h3>}
        <img src={integration.logo} alt={integration.name} />
      </>
    )
  }
  const Wrapper = (props) => (
    !tooltipOnCards ? <>{props.children}</> : (
      <WithTooltip
        tooltipId={"integration-" + integration.name}
        tooltipContent={integration.name}
      >{props.children}</WithTooltip>
    )
  )
  return (
    <Wrapper>
      <div className="p-2 col-lg-3 col-md-4 col-sm-6 hover-standard">
        <div
          className="integration-card d-flex align-items-center justify-content-center flex-column bg-white pr-4 pl-4 b-radius-10">
          {renderLogoAndText()}
        </div>
      </div>
    </Wrapper>
  )
}

IntegrationCard.propTypes = {
  integration: PropTypes.object,
  logoUpAndTextDown: PropTypes.bool,
  logoOnly: PropTypes.bool
}

export const IntegrationsSection = ({
                                      prop,
                                      integrations
                                    }) => {
  integrations = INTEGRATIONS_LIST
  return (
    <div className="w-100 bg-primary-yellow pt-70 pb-70 pl-4 pr-4 position-relative">
      <SectionIdOffset offsetPx={{ mobile: -100, desktop: -200 }} id={SECTION_IDS.INTEGRATIONS} mark={false} />
      <h2 className="d-block tac text-black">Integrations</h2>
      <h5 className="d-block tac pt-3 pb-3 font-weight-normal">
        Integrate with industry-leading customer service software or<br />
        any e-commerce platform of your choice.
      </h5>
      {/*<div className="d-grid gtc-repeat-4-1fr g-auto-rows-auto g-gap-15rem">*/}
      <div className="integration-cards-container row pr-lg-5 pl-lg-5">
        {integrations.map((integration) => (
          <IntegrationCard integration={integration} logoUpAndTextDown logoOnly={true} />
        ))}
      </div>
    </div>
  )
}

IntegrationsSection.propTypes = {
  prop: null
}