import React from "react"
import PropTypes from "prop-types"
import cx from 'classnames';
import moment from "moment"
import queryString from "query-string"
import { Button } from "./Button"
import { formatCalendlyUrl } from "../../utils/common"

export const RequestDemoButton = ({ inline = false, switchTextAndIcon = false, label = null }) => {
  const renderCnt = () => {
    if (switchTextAndIcon) {
      return (
        <>
          <span>Request a demo</span>
          <i className="flaticon-play-button ml-3" />
        </>
      )
    }
    return (
      <>
        <i className="flaticon-play-button mr-3" /><span>{label || "Request a demo"}</span>
      </>
    );
  }

  return (
    <Button
      openInNewTab
      withBtnBoxWrapper={!inline}
      isLink
      isExternalLink={true}
      link={formatCalendlyUrl()}
      types={["primary", "transparent"]}
    >
      {renderCnt()}
    </Button>
  )
}

RequestDemoButton.propTypes = {
  inline: PropTypes.bool,
  switchTextAndIcon: PropTypes.bool,
  label: PropTypes.string
}
