import React from "react"
import { FeatureCard } from "../FeatureCard/FeatureCard";

export const UseCasesSection = ({
                                     showCtaOnCards = true,
                                     displaySubtitle = false,
  variant,
                                   }) => {
  return (
    <div className="featured-services-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          {variant.subtitle && <span className="sub-title">{variant.subtitle}</span>}
          <h2>{variant.heading}</h2>
          <p>{variant.subheading}</p>
        </div>
        <div className="row">
          {variant.content.items.map((card) => (
            <FeatureCard
              key={card.heading}
              heading={card.heading}
              subheading={card.subheading}
              callToAction={!card.noCta ? card.ctaButton : null}
              noCta={card.noCta}
              displayCircles={card.displayAnimatedCircles}
              icon={card.icon}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
