import React from 'react';
import { Link } from "gatsby"
import { FeatureCard } from "../FeatureCard/FeatureCard"

export const BenefitsSection = ({
  variant,
}) => {
    return (
        <div className="services-area bg-f9f9f9 pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    {variant.subtitle && <span className="sub-title">{variant.subtitle}</span>}
                    <h2>{variant.heading}</h2>
                    <p>{variant.subheading}</p>
                </div>

                <div className="row">
                    <div className="row">
                        {variant.content.items.map((card) => (
                          <FeatureCard
                            equalHeight={true}
                            key={card.heading}
                            heading={card.heading}
                            subheading={card.subheading}
                            callToAction={!card?.noCta ? card.ctaButton : null}
                            noCta={card?.noCta}
                            displayCircles={card?.displayAnimatedCircles}
                            icon={card?.icon}
                          />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
