import React from "react"
import PropTypes from "prop-types"
import { inPixels, isMobileViewport } from "../utils/common"

export const SectionIdOffset = ({ offsetPx, id, mark = false, highlight = false }) => {
  return (
    <div
      id={id}
      style={{
        position: 'absolute', left: 0, right: 0, height: '25px', width: '100%', border: mark || highlight  ? '1px solid red' : null, zIndex: 999999,
        top: inPixels(isMobileViewport() ? offsetPx?.mobile : offsetPx?.desktop),
      }}
    />
  );
}

SectionIdOffset.propTypes = {
  offsetPx: PropTypes.object.isRequired,
  mark: PropTypes.bool,
  highlight: PropTypes.bool,
  id: PropTypes.string.isRequired,
}