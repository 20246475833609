import PropTypes from 'prop-types';

export const BannerContentVariantPt = PropTypes.shape({
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  primaryButtonLabel: PropTypes.string.isRequired,
  secondaryButtonLabel: PropTypes.string.isRequired,
});

export const BannerLayoutVariantPt = PropTypes.shape({
  hideBannerImageOnMobile: PropTypes.bool.isRequired,
  ctaButtonsSingleRow: PropTypes.bool.isRequired,
  requestDemoButton: PropTypes.shape({
    switchTextAndIcon: PropTypes.bool.isRequired,
  }).isRequired,
});


export const CommonPropTypes = {
  bannerVariant: PropTypes.shape({
    text: BannerContentVariantPt.isRequired,
    layout: BannerLayoutVariantPt.isRequired,
  }),
}
